/// Values for fontsizes
$fontsizes: (
  'tiny': 12px,
  'small': 15px,
  'base-mobile': 16px,
  'base': 18px,
  'large-body-mobile': 20px,
  'large-body': 24px,
  'title-huge-mobile': 36px,
  'title-huge': 48px,
  'title-h1-mobile': 32px,
  'title-h1': 44px,
  'title-h2-mobile': 20px,
  'title-h2': 28px,
  'large-button': 32px,
  'onboarding-tiny': 13px,
  'onboarding-small': 16px,
  'onboarding-base': 20px,
  'onboarding-title-h1': 32px,
  'onboarding-button': 24px,
) !default;
