@mixin as-h1 {
  font-family: fontfamily('title');
  font-weight: fontweight('medium');
  font-size: fontsize('title-h1');
  color: color('red-50');
  line-height: 1.27;

  @include mobile {
    font-size: fontsize('title-h1-mobile');
    line-height: 1.25;
  }
}

@mixin as-onboarding-h1 {
  font-family: fontfamily('title');
  font-weight: fontweight('medium');
  font-size: fontsize('onboarding-title-h1');
  line-height: 1.25;
}

@mixin as-h2 {
  font-family: fontfamily('title');
  font-weight: fontweight('medium');
  font-size: fontsize('title-h2');
  color: color('red-50');
  line-height: 1.28;
}

@mixin as-huge-title {
  font-family: fontfamily('title');
  font-weight: fontweight('medium');
  font-size: fontsize('title-huge');
  line-height: 1.28;

  @include mobile {
    font-size: fontsize('title-huge-mobile');
    line-height: 1.4;
  }
}
