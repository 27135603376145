@import 'shared';

.error-message {
  display: flex;

  & > svg {
    // Vertically align with first line
    margin-top: 3px;
  }

  &__message {
    flex: 1;
    font-size: fontsize('small');
    color: color('red-50');
    margin-left: 8px;
    line-height: 1.6;
  }
}
