@font-face {
  font-family: 'Graphik';
  src: url('Graphik-Regular.woff2') format('woff2'),
    url('Graphik-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('Graphik-Semibold.woff2') format('woff2'),
    url('Graphik-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Mikado';
  src: url('MikadoMedium.woff2') format('woff2'),
    url('MikadoMedium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
