p {
  line-height: 1.55;

  @include mobile {
    line-height: 1.5;
  }
}

a {
  color: color('orange-50');
  text-decoration: none;
  line-height: 1.55;
}
