// Colors variables
$colors: (
  'red-50': #ea3729,
  'gray-10': #373737,
  'gray-30': #818181,
  'gray-40': #aaaaaa,
  'gray-50': #c9c9c9,
  'gray-60': #e1e1e1,
  'gray-70': #eeeeee,
  'gray-80': #f5f5f5,
  'orange-40': #f77219,
  'orange-50': #ff8a33,
  'pinkish-grey': #c9c9c9,
  'white': #ffffff,
) !default;
