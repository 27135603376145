// Border radius
$osmo-button-border-radius: (
  'large': 39px,
  'base': 28px,
  'mobile': 16px,
);

// Shadow
$osmo-button-shadow-drop-width: (
  'base': 4px,
  'large': 6px,
);

// Spacings
$osmo-button-padding-vertical: (
  'large': 24px,
  'base': 11px,
  'mobile': 9px,
  'onboarding': 12px,
);
$osmo-button-padding-horizontal: (
  'large': 64px,
  'base': 26px,
  'mobile': 24px,
  'onboarding': 32px,
);

// Transformations
$osmo-button-hover-scale: 1.05;

// Colors
// Orange :
$osmo-button-color: color('orange-50');
$osmo-button-shadow-color: color('orange-40');
$osmo-button-text-color: color('white');

// Blue :
$osmo-button-blue-color: #0099ff;
$osmo-button-blue-shadow-color: #0064ef;
$osmo-button-blue-text-color: color('white');

// Animations
$osmo-button-click-animation-time: 100ms;
$osmo-button-hover-animation-time: 150ms;

@mixin pressed-state($size) {
  &::before {
    top: map-get($osmo-button-shadow-drop-width, $size);
  }

  .osmo-button__inner {
    transform: translateY(map-get($osmo-button-shadow-drop-width, $size));
  }
}
