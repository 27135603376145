@mixin mobile {
  @media (max-width: #{$width-mobile-max}) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: #{$width-tablet-max}) {
    @content;
  }
}

@mixin large-screen {
  @media (max-width: #{$width-large-screen-max}) {
    @content;
  }
}

@mixin fire-device {
  html[data-fire='true'] & {
    @content;
  }
}

@mixin not-fire-device {
  html[data-fire='false'] & {
    @content;
  }
}

@mixin touch-device {
  html[data-touch='true'] & {
    @content;
  }
}

@mixin not-touch-device {
  html:not([data-touch='true']) & {
    @content;
  }
}
