@import 'shared';

.survey-callback {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 80px 0;
  text-align: center;

  &__image {
    max-width: 325px;

    @include mobile {
      max-width: 255px;
    }
  }

  &__title {
    @include as-h1;
    color: color('gray-10');
  }

  &__image + &__title {
    margin-top: 40px;
  }

  &__title + &__text {
    margin-top: 12px;
  }
}

.survey-callback-header-link {
  font-size: fontsize('small');
  color: color('white');

  @include mobile {
    font-size: fontsize('tiny');
  }

  & > a {
    font-weight: fontweight('semi-bold');
    color: color('white');
  }
}
