@import 'reset';
@import 'shared';

@import 'base/base';
@import 'base/typography';

#root {
  height: 100%;
}

.site-header + .app-container {
  height: calc(100% - 80px);
}

.app-container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .site-footer {
    flex: 1;
  }
}
