html,
body {
  height: 100%;
  width: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: color('grey-50');
  font-family: fontfamily('body');
  font-size: fontsize('base');
  color: color('gray-10');

  @include mobile {
    font-size: fontsize('base-mobile');
  }
}
