@mixin margin-center {
  display: block;
  margin: 0 auto;
}

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin absolute-horizontal-center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
