/// Values for fontstacks
$fontfamilies: (
  'body': (
    'Graphik',
    -apple-system,
    BlinkMacSystemFont,
    'Avenir Next',
    'Avenir',
    'Segoe UI',
    'Lucida Grande',
    'Helvetica Neue',
    'Helvetica',
    'Fira Sans',
    'Roboto',
    'Noto',
    'Droid Sans',
    'Cantarell',
    'Oxygen',
    'Ubuntu',
    'Franklin Gothic Medium',
    'Century Gothic',
    'Liberation Sans',
    sans-serif,
  ),
  'title': (
    'Mikado',
    -apple-system,
    BlinkMacSystemFont,
    'Avenir Next',
    'Avenir',
    'Segoe UI',
    'Lucida Grande',
    'Helvetica Neue',
    'Helvetica',
    'Fira Sans',
    'Roboto',
    'Noto',
    'Droid Sans',
    'Cantarell',
    'Oxygen',
    'Ubuntu',
    'Franklin Gothic Medium',
    'Century Gothic',
    'Liberation Sans',
    sans-serif,
  ),
) !default;
